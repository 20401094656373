<template>
	<div>
		<v-row class="pt-3">
			<v-col cols="5"
				><h2 class="text-h4">Lista por rol de regantes</h2></v-col
			>
			<v-col cols="7">
				<IrrigatorListFilter :hide_watchman="hideSelectWatchman" />
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<IrrigatorList />
			</v-col>
		</v-row>

		<template v-if="showForm">
			<v-row>
				<v-col cols="4">
					<FileFeesPaidsFormAdd />
				</v-col>
				<v-col cols="4">
					<FileFeesFormAdd />
				</v-col>

				<v-col cols="4">
					<FileIrrigatorFormAdd />
				</v-col>

				<v-col cols="4">
					<FileChannelFormAdd />
				</v-col>

				<v-col cols="4">
					<FileLocationFormAdd />
				</v-col>

				<v-col cols="4">
					<FileBankFormAdd />
				</v-col>
			</v-row>
		</template>
	</div>
</template>

<script>
	import IrrigatorList from '@/components/IrrigatorList';
	import IrrigatorListFilter from '@/components/IrrigatorListFilter';
	import FileIrrigatorFormAdd from '@/components/FileIrrigatorFormAdd';
	import FileLocationFormAdd from '@/components/FileLocationFormAdd';
	import FileChannelFormAdd from '@/components/FileChannelFormAdd';
	import FileFeesPaidsFormAdd from '@/components/FileFeesPaidsFormAdd';
	import FileFeesFormAdd from '@/components/FileFeesFormAdd';
	import FileBankFormAdd from '@/components/FileBankFormAdd';
	import { mapState } from 'vuex';

	export default {
		name: 'irrigator',

		components: {
			IrrigatorList,
			FileIrrigatorFormAdd,
			FileChannelFormAdd,
			FileLocationFormAdd,
			FileFeesFormAdd,
			FileFeesPaidsFormAdd,
			IrrigatorListFilter,
			FileBankFormAdd,
		},

		computed: {
			...mapState({
				user: state => state.userControl.user,
			}),

			/* Mostrar los formularios a los usuarios de las areas segun su codigo, indicado en el array */
			showForm() {
				const code =
					this.user.association_area &&
					this.user.association_area.code
						? this.user.association_area.code
						: '';
				return [
					'sysadmin',
					'administrationm',
					'administration',
				].includes(code);
			},

			hideSelectWatchman() {
				return this.user.association_area.code == 'watchman';
			},
		},
	};
</script>
