<template>
	<v-card>
		<v-card-title> Actualizar cuotas pendientes </v-card-title>

		<v-card-text>
			<v-file-input
				accept=".xlsx, .xls"
				v-model="fileToUpload"
				truncate-length="15"
				:disabled="isLoading"
			></v-file-input>

			<v-btn
				class="primary"
				:disabled="!fileToUpload || (fileToUpload && isLoading)"
				:loading="fileToUpload && isLoading"
				@click="uploadFees"
			>
				Cargar
			</v-btn>
		</v-card-text>
	</v-card>
</template>

<script>
	/* Formulario para procesar excel con los datos de los localidades */
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'irrigator-fees-form-add',

		data() {
			return {
				fileToUpload: null,
			};
		},

		computed: {
			...mapState({
				isLoading: state => state.systemNotification.isLoading,
			}),
		},

		methods: {
			...mapActions('filesControl', ['uploadIrrigatorFees']),

			uploadFees() {
				if (this.fileToUpload)
					this.uploadIrrigatorFees(this.fileToUpload).finally(() => {
						this.fileToUpload = null;
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.add-irrigator {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
</style>
